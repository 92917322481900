.fixtures-with-teams-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.fixture-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.fixture-card:last-child {
  border-bottom: none;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.team-names {
  font-size: 0.9em;
  color: #555;
  text-align: center;
  width: 120px; /* Adjust this width as per your design */
}

.versus {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 1rem;
}

.home-score {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 1rem;
}

.away-score {
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 1rem;
}

.fixture-time {
  font-size: 0.9em;
  color: #555;
  text-align: center;
  margin-bottom: 1rem;
}

.score {
  font-size: 0.9em;
  text-align: center;
  margin-top: 5px;
  width: 100%; /* Ensures scores take full width */
}

.score-value {
  font-weight: bold; /* Increase font weight */
}

.score.negative {
  color: red;
}

.score.positive {
  color: green;
}

.score.neutral {
  color: grey;
}

.fixture-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
