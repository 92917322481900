.top-scorers {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.top-scorers__title {
  margin-bottom: 20px;
}

.top-scorers__table {
  width: 100%;
  border-collapse: collapse;
}

.top-scorers__header-row,
.top-scorers__body-row {
  border: 1px solid #ddd;
}

.top-scorers__header-cell,
.top-scorers__body-cell {
  padding: 10px;
}

.top-scorers-name {
  padding: 10px;
  width: 120px;
}

.top-scorers-name img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.top-scorers-name p {
  margin: auto;
}

.top-scorers-team {
  width: 120px;
}

.top-scorers-team img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.top-scorers-team p {
  margin: auto;
}

.top-scorers-goals {
  width: 40px;
}

.top-scorers__body-cell img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.top-scorers__body-cell p {
  margin: auto;
}

.top-scorers__header-cell {
  background-color: #f2f2f2;
}

.top-scorers__body-row:nth-child(even) {
  background-color: #f9f9f9;
}

.top-scorers-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.top-scorers-card:last-child {
  border-bottom: none;
}
