.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #0033a0;
  color: #ffffff;
  width: 100%;
  box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.1);
}

.header-logo {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
}

.header-logo img {
  height: 50px;
}

.header-nav {
  padding-right: 1rem;
}

.header-nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.header-nav ul li {
  margin: 0;
}

.header-nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.header-nav ul li a:hover {
  text-decoration: underline;
}
