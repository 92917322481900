.group-winners {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.group-winners__title {
  margin-bottom: 20px;
}

.group-winners__table {
  width: 100%;
  border-collapse: collapse;
}

.group-winners__header-row,
.group-winners__body-row {
  border: 1px solid #ddd;
}

.group-winners__header-cell,
.group-winners__body-cell {
  padding: 10px;
}

.group-winners__header-cell {
  background-color: #f2f2f2;
}

.group-winners__body-row:nth-child(even) {
  background-color: #f9f9f9;
}

.group-winners-team {
  width: 120px;
}

.group-winners-team img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.group-winners-team p {
  margin: auto;
}

.group-winners-points {
  width: 40px;
}

.group-winners-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.group-winners-card:last-child {
  border-bottom: none;
}
