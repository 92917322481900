body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  background-color: #0240FF; /* Fallback color */
  background-image: url('../../assets/background.svg');
  background-repeat: repeat;
}

.footy24-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footy24-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
