.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #0033a0;
  color: #ffffff;
  text-align: center;
  width: 100%;
  box-shadow: 0px -8px 6px rgba(0, 0, 0, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.footer-nav ul li {
  margin: 0;
}

.footer-nav ul li a {
  color: #ffffff;
  text-decoration: none;
}

.footer-nav ul li a:hover {
  text-decoration: underline;
}
