.scoreboard {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.scoreboard__title {
  margin-bottom: 10px;
}

.scoreboard__table {
  width: 100%;
  border-collapse: collapse;
}

.scoreboard__header-row,
.scoreboard__body-row {
  border: 1px solid #ddd;
}

.scoreboard__header-cell,
.scoreboard__body-cell {
  padding: 10px;
}

.scoreboard__header-cell {
  background-color: #f2f2f2;
}

.scoreboard__body-row.gold {
  background-color: gold; /* Gold for 1st place */
}

.scoreboard__body-row.silver {
  background-color: silver; /* Silver for 2nd place */
}

.scoreboard__body-row.bronze {
  background-color: #cd7f32; /* Bronze for 3rd place (using hexadecimal color) */
}

.scoreboard__body-row.wooden-spoon {
  background-color: #8b4513; /* Brown for wooden spoon position */
  color: #fff; /* White text color for contrast */
}

.scoreboard-info {
  font-size: small;
  color: gray;
  margin-bottom: 10px ;
}
