.team-lists-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
}

.team-lists-container h2 {
    margin-bottom: 20px;
}

.team-lists {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.team-list {
    flex: 1;
    margin: 0 10px;
}

.team-list h3 {
    margin-bottom: 10px;
}

.team-list table {
    width: 100%;
    border-collapse: collapse;
}

.team-list th,
.team-list td {
    padding: 10px;
    border: 1px solid #ddd;
}

.team-list th {
    background-color: #f2f2f2;
}

.team-list tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}