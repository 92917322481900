.scoring-system {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.scoring-system__title {
  margin-bottom: 20px;
}

.scoring-system__table {
  width: 100%;
  border-collapse: collapse;
}

.scoring-system__header-row,
.scoring-system__body-row {
  border: 1px solid #ddd;
}

.scoring-system__header-cell,
.scoring-system__body-cell {
  padding: 10px;
}

.scoring-system__header-cell {
  background-color: #f2f2f2;
}

.scoring-system__body-row:nth-child(even) {
  background-color: #f9f9f9;
}
